import axios from 'axios';
import { navigate } from 'gatsby';
import qs from 'qs';
import {
  CUSTOM_QUOTE_FORM_HANDLER,
  GATSBY_DOMAIN,
  hardTrim,
} from '../../../../utils';

export const submitQuote = (formValues, props, currentMetro) => {
  axios({
    method: 'POST',
    url: CUSTOM_QUOTE_FORM_HANDLER,
    data: qs.stringify({
      first_name: formValues.first_name,
      last_name: formValues.last_name,
      email: formValues.email,
      phone: `+${hardTrim(formValues.phone)}`,
      '00N1L00000F9eBV': formValues.location,
      oid: process.env.GATSBY_OID,
      lead_source: 'Custom Quote',
      description: `Instant Estimate: ${formValues.bodyAreas.join(' | ')} | ${
        formValues.description
      }`,
      retUrl: currentMetro.origin + '/specials/estimate',
      Campaign_ID: '70141000000TgDG',
      cloudamp__data__c: localStorage.getItem('cloudamp_value'),
    }),
    config: {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    },
  })
    .then(
      (res) => console.log(res.status, 'lead created'),
      axios({
        method: 'POST',
        url: `${GATSBY_DOMAIN}/quote.php`,
        data: qs.stringify({
          name: formValues.first_name,
          email: formValues.email,
          tel: formValues.phone,
          location: formValues.location,
          areas: `${formValues.bodyAreas.join(' | ')} | ${
            formValues.description
          }`,
          city: currentMetro.name,
        }),
      })
        .then(
          (res) => console.log(res),
          props.setSubmitting(false),
          props.setStatus('Success'),
          navigate('?quote-form-success')
        )
        .catch((err) => console.error(err))
    )
    .catch((err) => {
      console.error(err);
      props.setSubmitting(false);
      props.setStatus('Failed');
    });
};
