import React from 'react';
import { Link } from 'gatsby';
import GRAPHIC from '../../../images/specials/hero/Aug24Shavings-The-Pit_s.svg';
import './SpecialsHero.css';

function SpecialsHeroContent({ siteData, setShowSignUpModal }) {
  return (
    <div id='specials-hero' className='position-relative pt-0'>
      <div className='container py-lg-milan-50 position-relative'>
        <div className='row'>
          <div className='offset-lg-6 col-lg-6 offset-xl-5 col-xl-7 pt-4'>
            {/* only hide on md */}
            <figure className='pb-4 position-relative z-index-100 text-center d-none d-lg-block'>
              <img id='graphic' src={GRAPHIC} alt='Milan Laser Social' />
            </figure>

            <h1 className='text-uppercase pb-0 smalltitle d-block text-center'>
              Laser Hair Removal Specials
            </h1>
            <h5 className='pb-3 d-block text-uppercase text-center'>
              in {siteData.currentMetro.name}
            </h5>
            {siteData.currentMetro.grandOpening ? (
              <p>
                Ready to pretty up your pits? During our Grand Opening this
                month, you can win{' '}
                <strong>
                  30, 40, or 50% OFF unlimited laser hair removal treatments!
                </strong>{' '}
                <Link to='#guarantee' className='milan-text-primary'>
                  exclusive Unlimited Package™
                </Link>{' '}
                comes with every purchase and includes free touch-ups, so you’re
                covered for life at any of our 350+ locations.
              </p>
            ) : (
              <p>
                Ready to pretty up your pits? This month, you can win{' '}
                <strong>
                  30, 40, or 50% OFF unlimited laser hair removal treatments!
                </strong>
                . Our{' '}
                <Link to='#guarantee' className='milan-text-primary'>
                  exclusive Unlimited Package™
                </Link>{' '}
                comes with every purchase and includes FREE touch-ups, so you’re
                covered for life at any of our 350+ locations nationwide.
              </p>
            )}
            {/* Desktop Only */}
            <p className='d-none d-md-block'>
              <strong>Smooth-for-Life Bonus</strong>: Receive a{' '}
              <strong>FREE TREATMENT*</strong> on your underarms, bikini line,
              lower legs, face & neck, shoulders, or neckline when you complete
              a complimentary consultation this month.
            </p>
            {/* Mobile Only */}
            <p className='d-md-none'>
              <strong>Smooth-for-Life Bonus</strong>: Receive a{' '}
              <strong>FREE TREATMENT*</strong> when you complete a complimentary
              consultation this month.
            </p>
            <p>
              Make a pit stop at your{' '}
              <Link to='/locations/' className='milan-text-primary'>
                local Milan Laser
              </Link>{' '}
              by <strong>August 31</strong> to get your Scratch & Save card and
              reveal your discount!
            </p>
            <div className='my-4 text-center'>
              <Link className='milan-btn milan-cta-btn' to='#consult'>
                Book My Free Consult
              </Link>
            </div>
            <p className='mb-lg-0 milan-fp'>
              <span
                className='text-decoration-underline pointer milan-text-primary'
                onClick={() => setShowSignUpModal(true)}
              >
                Stay up to date with special discounts and sales by subscribing
                to our email list.
              </span>
              <span>
                {' '}
                We promise we’ll never spam you or sell your information.{' '}
                <span className='d-md-none'>
                  *Free treatment excludes body areas that are currently or have
                  been previously treated.
                </span>
                <span className='d-none d-md-inline'>
                  *Eligible body areas for free treatment include underarms,
                  bikini line, lower legs, face & neck, shoulders, or neckline.
                  Excludes previously treated areas.
                </span>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpecialsHeroContent;
