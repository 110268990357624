import React from "react";

export function AffordablePlansDivider() {
  return (
    <section className="divider-section w-100 py-4 milan-bg-navy d-none d-lg-block">
      <div className="container">
        <div className="row justify-content-center milan-text-white text-center py-1">
          <div className="col-10 col-md-12">
            <h3 className="subpointlg">
              My <strong className="milan-text-tur">results</strong> are amazing!
            </h3>
            <figure className="p-subhead">
              <blockquote className="mb-0">The staff is very professional, great location, and</blockquote>
              <blockquote className="mb-0">affordable payment plans make it easy to afford. <strong>- Kimberly N.</strong></blockquote>
            </figure>
          </div>
        </div>
      </div>
      <span id="financing"></span>
    </section>
  );
}
export default AffordablePlansDivider;
