import React, { useRef } from 'react';
import { Formik, Form } from 'formik';
import { FormikControl, ConsentMsg } from '..';
import * as Yup from 'yup';
import { CONTACT_FORM_HANDLER, hardTrim } from '../../../utils';
import useCloudampData from '../../../hooks/useCloudampData';

const initialValues = {
  first_name: '',
  last_name: '',
  phoneMask: '',
  email: '',
  '00N1L00000F9eBV': '',
  updates: true,
  description: '',
};

const contactSchema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  phoneMask: Yup.string().min(15, 'Invalid phone number').required('Required'),
  '00N1L00000F9eBV': Yup.string().required('Required'),
  description: Yup.string(),
  updates: Yup.boolean(),
});

export function ContactForm({ siteData }) {
  initialValues['00N1L00000F9eBV'] =
    siteData.currentMetro.stores[0].salesforceValue;
  const formRef = useRef(null);

  const onSubmit = () => {
    formRef.current.oid.value = process.env.GATSBY_OID;
    formRef.current.submit();
  };

  const cloudampData = useCloudampData();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={contactSchema}
      onSubmit={onSubmit}
      validateOnChange={false}
    >
      {(formik) => {
        return (
          <Form
            id='contact-form'
            className='w-100 pb-4'
            ref={formRef}
            method='POST'
            action={CONTACT_FORM_HANDLER}
          >
            <input
              type='hidden'
              name='cloudamp__data__c'
              value={cloudampData}
            />
            <input type='hidden' name='oid' />
            <input
              type='hidden'
              name='retURL'
              value={`${siteData.currentMetro.origin}/form/processing/`}
            />
            <input
              className='lead_source'
              type='hidden'
              name='lead_source'
              id='lead_source'
              value='Website'
            />

            <div className='row justify-content-center mx-auto pt-md-2 gx-2'>
              <div className='col-md-6 mb-3'>
                <FormikControl
                  control='input'
                  name='first_name'
                  placeholder='First Name*'
                  type='text'
                  role='input'
                  aria-required
                />
              </div>
              <div className='col-md-6 mb-3'>
                <FormikControl
                  control='input'
                  name='last_name'
                  placeholder='Last Name*'
                  type='text'
                  role='input'
                  aria-required
                />
              </div>
            </div>
            <div className='row justify-content-center mx-auto gx-2'>
              <div className='col-md-6 mb-3'>
                <FormikControl
                  control='phoneInput'
                  name='phoneMask'
                  placeholder='Phone Number*'
                  type='tel'
                  role='input'
                  aria-required
                />
                <input
                  type='hidden'
                  name='phone'
                  value={`+${hardTrim(formik.values.phoneMask)}`}
                />
              </div>
              <div className='col-md-6 mb-3'>
                <FormikControl
                  control='emailInput'
                  name='email'
                  placeholder='Your Email*'
                  role='input'
                  aria-required
                />
              </div>
              <div className='mb-3'>
                <FormikControl
                  control='selectLocation'
                  id='00N1L00000F9eBV'
                  name='00N1L00000F9eBV'
                  title='Location'
                  label='Location'
                  milanStores={siteData.milanStores}
                />
              </div>
            </div>
            <div className='row justify-content-center mx-auto overflow-hidden gx-2'>
              <div className='mb-2'>
                <FormikControl
                  control='textarea'
                  name='description'
                  placeholder='Hello...'
                  rows='3'
                  role='input'
                />
              </div>
            </div>
            <div className='row justify-content-center mx-auto gx-2'>
              <div className='custom-checkbox d-flex'>
                <FormikControl
                  control='mailchimp'
                  name='updates'
                  mailchimpID='mailchimp-contact'
                />
              </div>
            </div>

            <div id='actions-btns' className='row justify-content-center mt-4'>
              <div className='col-10 col-lg-6 text-center'>
                <button
                  className='milan-btn milan-red-btn w-100'
                  type='submit'
                  disabled={!formik.isValid}
                >
                  {' '}
                  Submit
                </button>
              </div>
              <div className='mt-3'>
                <ConsentMsg />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ContactForm;
