import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import ReactPlayer from "react-player";

export function HomeHeroVideo() {
  const [domLoaded, setDomLoaded] = useState(false);
  useEffect(() => {
    setDomLoaded(true);
  }, []);

  return (
    <section id="home-hero-video" className="video-container hero pt-0 pt-md-5 overflow-hidden">
      {domLoaded && (
        <ReactPlayer
          className="limit-height"
          playing={true}
          loop={true}
          controls={false}
          muted={true}
          width="100%"
          height="100%"
          playsinline={true}
          url="https://customer-b4lv92xmqdr867rr.cloudflarestream.com/86388844ea1d248fb25cf9e5e4880fe2/manifest/video.m3u8"
        />
      )}
      <div className="container pt-5 py-lg-5 my-lg-4">
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-8 col-lg-6 col-xl-7">
                <h1 className="milan-text-primary">Never Shave Again. Guaranteed.</h1>
              </div>
            </div>
            <div className="row">
              <div className="col col-md-6 col-xxl-5">
                {/* <p>
                  Laser hair removal is a{" "}
                  <strong>
                    permanent solution to unwanted hair<sup>&dagger;</sup>
                  </strong>
                  . We can remove hair from nearly any part of the body for<strong> less than the cost of a monthly wax</strong>. Treatments are fast
                  and there’s no recovery time (it&nbsp;can be done over lunch!).
                </p> */}
                <p>
                  Laser hair removal is a <strong>permanent solution to unwanted hair<sup>&dagger;</sup></strong>. We can remove hair from nearly anywhere on the body for <strong>less than the lifetime value of shaving and waxing</strong>. There’s no recovery time, and treatments are fast — they can even be done over lunch!
                </p>
              </div>
            </div>
            <div className="row mt-3 gy-3">
              <div className="col-md-auto text-center text-lg-start">
                <Link className="milan-btn milan-primary-btn" to="#works">
                  See How LHR Works
                </Link>
              </div>
              <div className="col-md-auto text-center text-lg-start">
                <Link className="milan-btn milan-primary-btn" to="/specials/#guarantee">
                  Get Unlimited Treatments
                </Link>
              </div>
            </div>
          </div>
        </div>
        <span id="works"></span>
      </div>
    </section>
  );
}

export default HomeHeroVideo;
