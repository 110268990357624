import React from 'react'
import { FACEBOOK_URL_START } from '../../utils'
import { Link } from "gatsby"

import FB_ICON_WHITE from '../../images/shared/Facebook_Icon_White.png'
import TIKTOK_ICON_WHITE from '../../images/shared/Tiktok_Icon_White.png'
import TWITTER_ICON_WHITE from '../../images/shared/Twitter_Icon_White.png'
import INSTA_ICON_WHITE from '../../images/shared/Instagram_Icon_White.png'

export function FooterSocialIcons({currentMetro}) {
  return (
    <div className="footer-social-icons">
      {
        currentMetro.stores.length === 1 ?
          <a className="me-2" href={`${FACEBOOK_URL_START}${currentMetro.stores[0].platforms.facebook}`} target="_blank"><img src={FB_ICON_WHITE} alt="Facebook Icon" /></a>
          :
          <Link to="/locations/contact/#social" className="me-2"><img src={FB_ICON_WHITE} /></Link>
      }
      <a href="https://twitter.com/milanlaser" className="me-2" target="_blank"><img src={TWITTER_ICON_WHITE} alt="Twitter Icon" /></a>
      <a href="https://www.instagram.com/milanlaser/" className="me-2" target="_blank"><img src={INSTA_ICON_WHITE} alt="Instagram Icon" /></a>
      <a href="https://www.tiktok.com/@milanlaserhairremoval" className="me-2" target="_blank"><img src={TIKTOK_ICON_WHITE} alt="Tiktok Icon" /></a>
    </div>
  )
}

export default FooterSocialIcons