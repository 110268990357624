import * as Yup from 'yup'

export function consultSchema() {
  const schema = Yup.object({
    first_name: Yup.string().required('Required'),
    last_name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    phoneMask: Yup.string().min(15, 'Invalid phone number').required('Required'),
    description: Yup.string(),
    updates: Yup.boolean()
  })
  return schema
}

export default consultSchema