import React, { useReducer, useRef, useEffect } from 'react';
import { Formik, Form } from 'formik';
import {
  UserInputs,
  LocationsDropdownWrapper,
  ConsultCheckbox,
  submitConsult,
  consultSchema,
  reducer,
  NearbyLocations,
  ShowAddressOrLink,
  initialFormData,
  initialUserData,
  FORM_ACTIONS,
  FORM_PROGRESS,
} from '.';
import {
  SELF_SCHEDULE_FORM_HANDLER,
  STANDARD_INQUIRY_FORM_HANDLER,
} from '../../../utils';

export function MultipleStores({ siteData }) {
  const [formState, dispatch] = useReducer(reducer, initialFormData);

  const formRef = useRef(null);
  const succesRef = useRef(null);

  const cloudampDataRef = useRef(null);

  //used to capture the cloudamp data value and store it in localStorage
  useEffect(() => {
    // Wait for 3 seconds before accessing the value
    const timer = setTimeout(() => {
      if (cloudampDataRef.current) {
        // Set the value of localStorage to the value cloudampDataRef.current.value
        localStorage.setItem('cloudamp_value', cloudampDataRef.current.value);
      }
    }, 3000); // 3000 milliseconds = 3 seconds
    // Cleanup timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <div id='consult-form' className='hr-divider hr-divider-consult'>
      <h2 className='text-center subheadsm'>Book Your Free Consult</h2>
      <Formik
        initialValues={initialUserData}
        validationSchema={consultSchema}
        onSubmit={() => submitConsult(formState, siteData, succesRef, formRef)}
        validateOnChange={false}
      >
        {
          // This way we can get access to all formik props
          (formik) => {
            return (
              <Form
                className='container'
                ref={formRef}
                action={
                  formState.include.formAction === FORM_ACTIONS.selfSchedule
                    ? SELF_SCHEDULE_FORM_HANDLER
                    : STANDARD_INQUIRY_FORM_HANDLER
                }
                method='POST'
              >
                <input
                  ref={cloudampDataRef}
                  type='hidden'
                  name='cloudamp__data__c'
                />
                <input type='hidden' name='oid' />
                <input
                  id='success'
                  type='hidden'
                  name='retURL'
                  ref={succesRef}
                  value=''
                />

                <input
                  type='hidden'
                  name='city'
                  value={`${formState?.metro?.name}`}
                />
                <input
                  type='hidden'
                  name='state'
                  value={`${formState?.metro?.stateAbbrev}`}
                />

                <input
                  className='lead_source'
                  type='hidden'
                  name='lead_source'
                  id='lead_source'
                  value={formState.include.leadsource}
                />
                <input
                  type='hidden'
                  name='Campaign_ID'
                  id='campaign'
                  value={formState.include.campaignId}
                />
                <UserInputs
                  isQuestion={
                    formState.include.formAction === FORM_ACTIONS.question
                  }
                  formik={formik}
                />
                <div className='row locations-container'>
                  <div
                    className={`col locations-wrapper ${
                      formState.include.formAction !== '' &&
                      formState.include.formProgress !==
                        FORM_PROGRESS.initiateQuestion
                        ? 'toggle'
                        : ''
                    }`}
                  >
                    <NearbyLocations
                      currentMetro={siteData.currentMetro}
                      dispatch={dispatch}
                    />
                    <LocationsDropdownWrapper
                      {...{ formState, dispatch, siteData }}
                    />
                    <div className='row justify-content-center selected-location-container mb-3'>
                      <div
                        className={`col-xl-10 selected-location-wrapper ${
                          formState.store.salesforceValue &&
                          formState.store.salesforceValue !== ''
                            ? 'toggle'
                            : ''
                        }`}
                      >
                        <div className='mb-0 text-center'>
                          {(formState.store.open ||
                            formState.include.formAction ===
                              FORM_ACTIONS.question) && (
                            <ShowAddressOrLink
                              formState={formState}
                              currentMetro={siteData.currentMetro}
                            />
                          )}
                          {!formState.store.open &&
                            formState.include.formAction !==
                              FORM_ACTIONS.question && (
                              <div className='d-flex justify-content-center mb-3'>
                                <div
                                  className='alert alert-warning col text-center p-2 mb-0'
                                  role='alert'
                                >
                                  Selected location is not open yet, but you can
                                  still
                                  <span
                                    onClick={() =>
                                      dispatch({
                                        type: 'formAction',
                                        formAction: FORM_ACTIONS.question,
                                        formProgress:
                                          FORM_PROGRESS.initiateQuestion,
                                        user: formik.values,
                                      })
                                    }
                                  >
                                    &nbsp;submit a question
                                  </span>
                                  ! Or select a different location.
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id='actions-btns'
                  className='row gx-1 gy-4 justify-content-center'
                >
                  <div className='col-sm-5 col-lg-4 col-xl-3 text-center'>
                    {
                      // No button clicked yet / Initial setup
                      formState.include.formAction === '' &&
                        formState.include.formProgress === '' && (
                          <button
                            type='button'
                            className='milan-btn milan-navy-btn w-100'
                            onClick={() =>
                              dispatch({
                                type: 'formAction',
                                formAction: FORM_ACTIONS.question,
                                formProgress: FORM_PROGRESS.initiateQuestion,
                                user: formik.values,
                              })
                            }
                          >
                            Ask A Question
                          </button>
                        )
                    }
                    {
                      // First See Available Times Button clicked
                      formState.include.formAction ===
                        FORM_ACTIONS.selfSchedule &&
                        formState.include.formProgress ===
                          FORM_PROGRESS.selectLocation && (
                          <button
                            type='button'
                            className='milan-btn milan-fallback-btn w-100'
                            onClick={() =>
                              dispatch({
                                type: 'formAction',
                                formAction: '',
                                formProgress: '',
                                user: formik.values,
                              })
                            }
                          >
                            Go Back
                          </button>
                        )
                    }
                    {formState.include.formAction === FORM_ACTIONS.question && (
                      <>
                        {
                          // Ask A Question button clicked
                          formState.include.formProgress ===
                            FORM_PROGRESS.initiateQuestion && (
                            <button
                              type='button'
                              className='milan-btn milan-navy-btn w-100'
                              onClick={() =>
                                dispatch({
                                  type: 'formAction',
                                  formAction: FORM_ACTIONS.question,
                                  formProgress: FORM_PROGRESS.selectLocation,
                                  user: formik.values,
                                })
                              }
                              disabled={!formik.isValid}
                            >
                              Next
                            </button>
                          )
                        }
                        {
                          // Next button clicked
                          formState.include.formProgress ===
                            FORM_PROGRESS.selectLocation && (
                            <button
                              className='milan-btn milan-navy-btn w-100'
                              type='submit'
                              disabled={!formik.isValid ? true : false}
                            >
                              Send Question
                            </button>
                          )
                        }
                      </>
                    )}
                  </div>

                  <div className='d-none d-sm-block col-sm-2 col-lg-1 text-center'>
                    <div className='my-3 my-md-2'>
                      <strong>OR</strong>
                    </div>
                  </div>

                  <div className='col-sm-5 col-lg-4 col-xl-3 text-center'>
                    {
                      // No button clicked yet / Initial setup
                      formState.include.formAction === '' &&
                        formState.include.formProgress === '' && (
                          <button
                            type='button'
                            className='milan-btn milan-red-btn w-100'
                            onClick={() =>
                              dispatch({
                                type: 'formAction',
                                formAction: FORM_ACTIONS.selfSchedule,
                                formProgress: FORM_PROGRESS.selectLocation,
                                user: formik.values,
                              })
                            }
                          >
                            See Available Times
                          </button>
                        )
                    }
                    {
                      // First See Available Times Button clicked
                      formState.include.formAction ===
                        FORM_ACTIONS.selfSchedule &&
                        formState.include.formProgress ===
                          FORM_PROGRESS.selectLocation && (
                          <button
                            id='local-schedule-btn'
                            type='submit'
                            className='milan-btn milan-red-btn w-100'
                            disabled={
                              !formState.store.open || !formik.isValid
                                ? true
                                : false
                            }
                          >
                            See Available Times
                          </button>
                        )
                    }
                    {
                      // Form Action is Question
                      formState.include.formAction ===
                        FORM_ACTIONS.question && (
                        <>
                          {
                            // Ask A Question button clicked
                            formState.include.formProgress ===
                              FORM_PROGRESS.initiateQuestion && (
                              <button
                                type='button'
                                className='milan-btn milan-fallback-btn w-100'
                                onClick={() =>
                                  dispatch({
                                    type: 'formAction',
                                    formAction: '',
                                    formProgress: '',
                                    user: formik.values,
                                  })
                                }
                              >
                                Go Back
                              </button>
                            )
                          }
                          {
                            // Next button clicked
                            formState.include.formProgress ===
                              FORM_PROGRESS.selectLocation && (
                              <button
                                type='button'
                                className='milan-btn milan-fallback-btn w-100'
                                onClick={() =>
                                  dispatch({
                                    type: 'formAction',
                                    formAction: FORM_ACTIONS.question,
                                    formProgress:
                                      FORM_PROGRESS.initiateQuestion,
                                    user: formik.values,
                                  })
                                }
                              >
                                Go Back
                              </button>
                            )
                          }
                        </>
                      )
                    }
                  </div>
                </div>
                <ConsultCheckbox />
              </Form>
            );
          }
        }
      </Formik>
    </div>
  );
}

export default MultipleStores;

// const onSubmit = async () => {
//   const data = qs.stringify({
//     'oid': process.env.GATSBY_OID,
//     'lead_source': formState.include.leadsource,
//     '00N1L00000F9eBV': formState.store.salesforceValue,
//     'retURL': `${MILAN_SITE_START}.my.site.com/s/?firstname=${formState.user.first_name}&lastname=${formState.user.last_name}&phone=${formState.user.phone.slice(3)}&email=${formState.user.email}&zip=${formState.store.zipCode}&success_url=${siteData.currentMetro.origin}/form/self-success-force`,
//     'Campaign_ID': formState.include.campaignId,
//     'first_name': formState.user.first_name + ' ' +  formState.user.last_name,
//     'last_name': formState.user.last_name,
//     'phone': formState.user.phone,
//     'zip': formState.store.zipCode,
//     'description': formState.user.description,
//     'email': formState.user.email,
//     'updates': formState.user.updates,
//   })
//   await axios.post(STANDARD_INQUIRY_FORM_HANDLER, data).then(res => {
//     console.log(res.status, 'form submitted')
//     console.log(res)
//     navigate("/form/self-success-force")
//   }).catch((err) => {
//     console.error(err)
//    })
// }
