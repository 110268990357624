import React from "react"
import { BAHeroLayout, BA_BODY_AREAS, splitAreaNodes } from "."
import { graphql, useStaticQuery } from 'gatsby'

export function Bikini_BA_Hero() {
	const bikini_ba_photos = useStaticQuery(graphql`
		query bikini_ba_photos {
			allFile(
				filter: {sourceInstanceName: {eq: "globalImages"}, relativeDirectory: {eq: "process/beforeafter/bikini"}}
			) {
				edges {
					node {
						name
						childImageSharp {
							gatsbyImageData(quality: 90, placeholder: BLURRED, webpOptions: {quality: 90})
						}
					}
				}
			}
		}
	`).allFile.edges
  const bikini_area = BA_BODY_AREAS.find(area => area.area === 'bikini')
  bikini_area.ba_photos = splitAreaNodes(bikini_ba_photos)

  return (
    <BAHeroLayout area={bikini_area}>
		<h1 className="smalltitle text-center pb-2 milan-text-primary">Laser Hair Removal</h1>
      <h1 className="text-center pb-2 milan-text-primary">Bikini Before &amp; After Photos</h1>
      <p className="anchor-blue mt-4">Wondering what kind of results you can expect from bikini area or Brazilian style laser hair removal treatments at Milan? Most of our clients have the smooth, hair-free bikini area that they’ve always wanted in 7 to 10 treatments. Don’t just take our word for it though—<strong>check out these before and after photos of real Milan clients!</strong></p>
    </BAHeroLayout>
  );
}

export default Bikini_BA_Hero